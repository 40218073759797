import React from 'react';
import './App.css';
import Startheader from './sections/header/Startheader';
import Startservices from './sections/services/Startservices';
import Startportfolio from './sections/portfolio/Startportfolio';
import Startabout from './sections/about/Startabout';
import Startteam from './sections/team/Startteam';
import Startclient from './sections/clients/Startclient';
import Startcontact from './sections/contact/Addcontact';
import Startfooter from './sections/footer/Startfooter';
//import ContactServices from './services/contact.service';

// const retrieveContacts = () => {
//   ContactServices.getAll()
//     .then(response => {
//       //setTutorials(response.data);
//       console.log(response.data);
//     })
//     .catch(e => {
//       console.log('error');
//     });
// };

class App extends React.Component {


  render(){
    return (
      <div>
        <Startheader />
        <Startabout/>
        <Startservices/>
        <Startclient/>
        <Startportfolio/>
        <Startteam/>
        <Startcontact/>
        <Startfooter/>
  </div>
    )  
  }
}

export default App;
