import axios from "axios";
//const corsUrls = ['http://localhost:3001', 'http://localhost:9090', 'http://localhost:9090/api']
//axios.defaults.baseURL = 'http://127.0.0.1:8000' //Dajngo rest url
export default axios.create({
  baseURL: "http://localhost:3001",
  headers: {
    "Content-type": "application/json",
    //"Access-Control-Allow-Origin": "*",
    //"Access-Control-Allow-Origin": "http://localhost:3001", // more https://namespaceit.com/blog/axios-request-has-been-blocked-by-cors-no-access-control-allow-origin-header-is-present-on-the-requested-resource
    //'Access-Control-Allow-Credentials':true,
    //'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  }
});
