const Startabout = () => {
    return (
        <section className="page-section" id="about">
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase"> A propos de moi</h2>
                <h3 className="section-subheading text-muted">Je vous souhaite de progresser en faisant ce qui est bien.</h3>
            </div>
                <p className="text-muted">
                Hello ! J'aime la photographie, le vtt, écrire et bien sur coder. 
                J’ai eu une formation en réseau pendant tout mon cursus universitaire, 
                jusqu’a ce que je decide de faire du developpement logiciel ma profession, 
                donc j’ai du en quelque sorte repartir de zéro. 
                Aujourd’hui je me rejoins fort en travaillant comme 
                consultant en developpement logiciel et web et 
                c’est avec plaisir que je vous accompagnerais dans votre projet.
                </p>
        </div>
    </section>
    );
};
export default Startabout;