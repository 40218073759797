const HeaderStart = ()=>{
    const imgHeader = {
        backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/header3-bg.jpg'})`
    }

    return (
        <div className="header">
                    <header className="masthead" style={imgHeader}>
            <div className="container">
                <div className="masthead-subheading ">I am <span className="text-white">Durrel,</span></div>
                <div className="masthead-heading text-uppercase">Software developer</div>
                <a className="btn btn-primary btn-xl text-uppercase" href="#services">And I do that</a>
            </div>
        </header>
        </div>
    )
}

export default HeaderStart