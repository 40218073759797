/*import React from 'react';*/
import { StrictMode } from 'react';
import {createRoot} from 'react-dom/client';
import App from './App.js';

const container = document.getElementById('root');
const rootx = createRoot(container); // createRoot(container!) if you use TypeScript
rootx.render(
  <StrictMode>
  <App />
</StrictMode>
);

/*ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>, document.getElementById('root')
)*/

