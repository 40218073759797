import '../../css/style.css';
import HeaderStart from './header.js';
import Navigator from './navigator.js';
const Startheader = () => {

    return (
        <div className="startheader">
        <Navigator />
        <HeaderStart />

        </div>
    );
};
export default Startheader;