import React from 'react';

class Navigator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showMenuHam:false
       }
       this.handleShowMenuHam = this.handleShowMenuHam.bind(this)
       
    }
    handleShowMenuHam (){
        this.setState({showMenuHam: !this.state.showMenuHam })
    }
    render(){
        return (
            <div className="navigator">
                <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
                <div className="container">
                    <a className="navbar-brand" href="#page-top"><img src="assets/img/navbar-logo.svg" alt="..." /></a>
    
                    <div className={`collapse0 navbar-collapse0 ${this.state.showMenuHam ? "show-nav": "hidden-nav"}`} id="navbar-Responsive">
                        <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                            <li className="nav-item"><a className="nav-link" href="#services">Services</a></li>
                            <li className="nav-item"><a className="nav-link" href="#portfolio">Portfolio</a></li>
                            <li className="nav-item"><a className="nav-link" href="#about">About</a></li>
                            <li className="nav-item"><a className="nav-link" href="#BLOG">Blog</a></li>
                            <li className="nav-item"><a className="nav-link" href="#contact">Contact</a></li>
                        </ul>
                        
                    </div>
                    <button className={`navbar-ham ${this.state.showMenuHam ? "show-ham": "hidden-ham"}`}  type="button" onClick={this.handleShowMenuHam}>
                        <span className="bar-ham"></span>                
                    </button>
                    
                </div>
            </nav>
            </div>
        )
    }
    
}
export default Navigator;