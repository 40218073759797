const Startservices = () => {
    return (
        <section className="page-section" id="services">
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase">Services</h2>
                <h3 className="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>
            </div>
            <div className="row text-center">
                <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                        <i className="fas fa-circle fa-stack-2x text-primary"></i>
                        <i className="fas fa-shopping-cart fa-stack-1x fa-inverse"></i>
                    </span>
                    <h4 className="my-3">Logiciels</h4>
                    <p className="text-muted">Nous créons des applications web et des logiciels qui repondent aux besoins de nos clients et partenaires en utilisant des tecnologies informatiques actuelles.</p>
                </div>
                <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                        <i className="fas fa-circle fa-stack-2x text-primary"></i>
                        <i className="fas fa-laptop fa-stack-1x fa-inverse"></i>
                    </span>
                    <h4 className="my-3">Webmarketing</h4>
                    <p className="text-muted">Nous vous accompagnons aussi dans conception 
                    d’une strategie de marketing digital, précisement 
                    par l’utilisation des services de publicité de certains reseaux sociaux 
                    comme Facebook, le referencement, les tunnel de vente et d’autre outils 
                    qui vous permettra d’augmenter vos ventes (cf Blog).</p>
                </div>
                <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                        <i className="fas fa-circle fa-stack-2x text-primary"></i>
                        <i className="fas fa-lock fa-stack-1x fa-inverse"></i>
                    </span>
                    <h4 className="my-3">Mobile</h4>
                    <p className="text-muted">Aujourd’hui les smartphones etant devenu incontournable, 
                    la création d’une application sera pour vous un excellent moyen d’augmenter
                     le potentiel de votre organisation.</p>
                </div>
                <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                        <i className="fas fa-circle fa-stack-2x text-primary"></i>
                        <i className="fas fa-lock fa-stack-1x fa-inverse"></i>
                    </span>
                    <h4 className="my-3">Wordpress et devéloppement</h4>
                    <p className="text-muted">Nous créons des sites web vitrine et ecommerce via le Cms populaire Wordpress.</p>
                </div>
                <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                        <i className="fas fa-circle fa-stack-2x text-primary"></i>
                        <i className="fas fa-lock fa-stack-1x fa-inverse"></i>
                    </span>
                    <h4 className="my-3">Web design</h4>
                    <p className="text-muted">Il nous arrive aussi de faire de l’integration html/css. Photoshop et Illustrator sont d'un grand aide.</p>
                </div>
                <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                        <i className="fas fa-circle fa-stack-2x text-primary"></i>
                        <i className="fas fa-lock fa-stack-1x fa-inverse"></i>
                    </span>
                    <h4 className="my-3">Formations</h4>
                    <p className="text-muted">Votre entreprise peut avoir besoin former vos collaborateurs
                     sur une technlogie web donné, faite nous confiance pour les donner des cours personnalisés
                      et adapté à leurs besoins. Nous formons sur Ms excel, Ms word, Ms outlook, wordpress, etc</p>
                </div>
            </div>
        </div>
    </section>
    );
};
export default Startservices;