import { Component } from "react";
import contactDataService from "../../services/contact.service";
export default class AddContact extends Component {
  constructor(props) {
    super(props);
    this.onChangeFullname = this.onChangeFullname.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeCompany = this.onChangeCompany.bind(this);
    this.onChangeProject = this.onChangeProject.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.newContact = this.newContact.bind(this);
    this.state = {
      id: null,
      fullName: "",
      email: "", 
      address: "", 
      company: "",
      phone: "",
      project: "",
      message: "",
      submitted: false
    };
  }
  onChangeFullname(e) {
    this.setState({
      fullName: e.target.value
    });
    console.log(this.state.fullName)
  }
  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }
  onChangeAddress(e) {
    this.setState({
      address: e.target.value
    });
  }
  onChangeCompany(e) {
    this.setState({
      company: e.target.value
    });
  }
  onChangeMessage(e) {
    this.setState({
      message: e.target.value
    });
  }
  onChangePhone(e) {
    this.setState({
      phone: e.target.value
    });
  }

  onChangeProject(e) {
    this.setState({
      project: e.target.value
    });
  }

  submitHandler(event) {
    event.preventDefault();

    console.log('saveContact')
    var data = {
      fullname: this.state.fullName,
      email: this.state.email,
      address: this.state.address,
      company: this.state.company,
      message: this.state.message,
      phone: this.state.phone,
      project: this.state.project,
      response: false
    };
    console.log(data)

    contactDataService.create(data)
    .then(response => {
      this.setState({
        address: response.data.address,
        fullName: response.data.fullname,
        company: response.data.company,
        message: response.data.message,
        project: response.data.project,
        phone: response.data.phone,
        email: response.data.email,
        response: 'false'
      });
     // console.log(response.data);
    })
    .catch(error => {
      //console.log(error);
      if (error.response) {
        // Request made and server responded
        console.log('Request made and server responded');
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log('The request was made but no response was received');
        console.log(error);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Something happened in setting up the request that triggered an Error');
        console.log('Error', error.message);
      }
    });

  }
  newContact() {
    this.setState({
      id: null,
      fullName: "",
      email: "", 
      phone: "", 
      address: "", 
      company: "",
      project: "",
      message: "",
      submitted: false
    });
  }
  render() {
    const styleContact = {
      backgroundImage: `url(${process.env.PUBLIC_URL+ "/assets/img/map-image.png"})`
  }
  return (
      <section className="page-section" id="contact" style={styleContact}>
      <div className="container">
          <div className="text-center">
              <h2 className="section-heading text-uppercase">Envoyer nous votre projet</h2>
              <h3 className="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>
          </div>

          <form id="contactForm" onSubmit ={(event) => this.submitHandler(event)} >
              <div className="row align-items-stretch mb-5">
                  <div className="col-md-6">
                      <div className="form-group">
                          <input className="form-control" id="fullname" type="text" placeholder="Your fullName *"
                          onChange={this.onChangeFullname} value={this.state.fullName} name="fullname"/>
                      </div>
                      <div className="form-group">
                          <input className="form-control" id="email" type="email" placeholder="Your Email *" 
                          onChange={this.onChangeEmail} value={this.state.email} name="email" />
                    </div>
                      <div className="form-group">
                          <input className="form-control" id="phone" type="tel" placeholder="Your Phone *" data-sb-validations="required" 
                          onChange={this.onChangePhone} value={this.state.phone} name="phone" />
                      </div>
                      <div className="form-group">
                          <input className="form-control" id="address" type="text" placeholder="Your address *" data-sb-validations="required" 
                          onChange={this.onChangeAddress} value={this.state.address} name="address" />
                      </div>
                      <div className="form-group mb-md-0">
                          <input className="form-control" id="company" type="text" placeholder="Your Company *" data-sb-validations="required"
                          onChange={this.onChangeCompany} value={this.state.company} name="company" />
                     </div>
                  </div>
                  <div className="col-md-6">

                      <div className="form-group">
                        <select className="form-control" id="project" type="text" onChange={this.onChangeProject} value={this.state.project} name="project"
                        placeholder="Your Project *" >
                          <option value="aucun">Non defini</option>
                          <option value="logiciel">Conception de logiciel</option>
                          <option value="wordpress">Conception de site Web avec Wordpress</option>
                          <option value="surmesure">Conception de site Web sur mesure</option>
                          <option value="webapp">Conception d'application Web</option>
                          <option value="mobileapp">Conception d'application mobile</option>
                          <option value="integrationweb">Intégration web ui</option>
                          <option value="webmarketing">marketing web</option>
                          <option value="formation">Formations</option>
                        </select>
                      </div>
                      <div className="form-group form-group-textarea mb-md-0">

                          <textarea className="form-control" id="message" placeholder="Your Message *" 
                          onChange={this.onChangeMessage} value={this.state.message} name="message"></textarea>
                      </div>
                  </div>
              </div>
 
              <div className="d-none" id="submitSuccessMessage">
                  <div className="text-center text-white mb-3">
                      <div className="fw-bolder">Form submission successful!</div>
                      To activate this form, sign up at
                      <br />
                      <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                  </div>
              </div>
 
              <div className="d-none" id="submitErrorMessage"><div className="text-center text-danger mb-3">Error sending message!</div></div>
              <div className="text-center"><button className="btn btn-primary btn-xl text-uppercase" id="submitButton" >Send Message</button></div>
          </form>
      </div>
  </section>
  );
  }
}

